/* eslint-disable max-len */
// Unfortunately, these CSS files need to be explicitly imported because for some reason,
// Razzle isn't bundling the CSS properly in the production bundle otherwise
import './App.css';
import './styles/shared.module.css';
import './components/Layout/Layout.module.css';
import './components/Footer/Footer.module.css';
import './components/Nav/Nav.module.css';
import './components/FeedList/FeedList.module.css';
import './components/FeedCard/FeedCard.module.css';
import './pages/faq.module.css';

import { getEndTime } from './libs/client-utils';

// Note: Include this only if the polyfill service is still misbehaving
// import './intersection-observer.polyfill';


// Lazy load images
const intersectionConfig = {
  rootMargin: '50px 0px',
  threshold: 0.01,
};

const imageObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        // Stop watching and load the image
        imageObserver.unobserve(entry.target);
        entry.target.src = entry.target.dataset.src;
      }
    });
  },
  intersectionConfig,
);

// Attach observer to images
const images = document.querySelectorAll('[data-img]');
images.forEach((image) => {
  imageObserver.observe(image);
});

if (module.hot) {
  module.hot.accept();
}

/**
 * Event listener handler for showing the popup
 * @param {HTMLEvent} event
 */
const showPopup = (event) => {
  const { target } = event;
  const description = target.dataset.feedDescription
    && decodeURIComponent(target.dataset.feedDescription);
  const title = target.dataset.feedTitle
    && decodeURIComponent(target.dataset.feedTitle);

  let popup = document.querySelector('#feedCardDescription');
  if (!popup) {
    const element = document.createElement('div');
    element.setAttribute('id', 'feedCardDescription');
    element.style.cssText = `
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 2px;
      display: none;
      padding: 5px;
      position: absolute;
      width: 200px;
    `;

    document.body.appendChild(element);
    popup = element;
  }

  if (description) {
    popup.innerHTML = `
      <h4 style="margin-top: 0">${title}</h4>
      <p style="margin-bottom: 0">${description}</p>
    `;
    popup.dataset.isVisible = true;
    popup.style.display = 'block';
  }
};

/**
 * Event listener handler for hiding description popup
 * @param {HTMLEvent} event
 */
const hidePopup = () => {
  const popup = document.querySelector('#feedCardDescription');
  if (popup) {
    popup.innerHTML = '';
    popup.dataset.isVisible = false;
    popup.style.display = 'none';
    popup.style.left = null;
    popup.style.top = null;
  }
};

/**
 * Event listener handler for updating the current position of the popup
 * @param {HTMLEvent} event
 */
const updatePopupLocation = (event) => {
  const popup = document.querySelector('#feedCardDescription');
  if (popup) {
    popup.style.left = `${event.pageX + (-10)}px`;
    popup.style.top = `${event.pageY + 30}px`;
  }
};

const popupListeners = [
  {
    // 1. When the mouse "enters" the element, show the popup
    event: 'mouseenter',
    handler: showPopup,
  },
  {
    // 2. As the mouse "moves" on the element, update the position of the popup
    event: 'mousemove',
    handler: updatePopupLocation,
  },
  {
    // 3. When the mouse moves out of the element, hide the popup
    event: 'mouseleave',
    handler: hidePopup,
  },
];

/**
 * Attach countdown listeners when the card is in view
 */
const feedCardObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      let timerInterval;

      if (entry.isIntersecting > 0) {
        const { target } = entry;

        const timerElement = target.querySelector('[data-feed-end]');
        const { feedEnd } = timerElement.dataset;

        timerInterval = setInterval(() => {
          const [timeLeft, total] = getEndTime(
            // Replace spaces in date string to fix issues with Safari. Add a 'Z' at the end to use the local timezone
            new Date(`${feedEnd.replace(/ /g, 'T')}Z`),
          );

          timerElement.innerHTML = timeLeft;
          if (total <= 0) {
            clearInterval(timerInterval);
          }
        }, 1000);

        // Attach event listeners
        popupListeners.map(listener => target.addEventListener(
          listener.event, listener.handler, { passive: true },
        ));
      } else {
        clearInterval(timerInterval);

        // Clear event listeners
        popupListeners.map(listener => entry.target.removeEventListener(
          listener.event, listener.handler,
        ));
      }
    });
  },
  intersectionConfig,
);

// Attach listener to feed cards
document.querySelectorAll('[data-feed-card]').forEach(item => feedCardObserver.observe(item));


// Attach tab switcher listener for FAQ page
if (window.location.pathname === '/faq' || window.location.pathname === '/contact') {
  const tabHeaders = document.querySelectorAll('[data-tab-header]');
  const tabContents = document.querySelectorAll('[data-tab-content-id]');

  const setActiveTab = (activeTab) => {
    // Show content
    tabContents.forEach((tabContent) => {
      const contentID = tabContent.dataset.tabContentId;

      if (contentID === activeTab) {
        tabContent.dataset.tabContentActive = true;
        return;
      }

      tabContent.removeAttribute('data-tab-content-active');
    });

    // Add indicator to header
    tabHeaders.forEach((header) => {
      const headerID = header.dataset.tabId;

      if (headerID === activeTab) {
        header.dataset.tabHeaderActive = true;
        return;
      }

      header.removeAttribute('data-tab-header-active');
    });
  };

  // If it's the contact page, set the contact tab as the active tab
  if (window.location.pathname === '/contact') {
    setActiveTab('7');
  }

  tabHeaders.forEach((tabHeader) => {
    tabHeader.addEventListener('click', () => {
      const tabID = tabHeader.dataset.tabId;

      setActiveTab(tabID);
    });
  });
}
