const normalizeDigit = digit => (digit > 9 ? digit : `0${digit}`);

/**
 * Get the end time for a feed item
 * @param {Date} endTime Endtime for a feed
 */
export const getEndTime = (endTime) => {
  const t = Date.parse(endTime) - Date.parse(new Date());
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));

  let dayText;

  if (days === 0) {
    dayText = 'Today';
  } else {
    const plural = days > 1 ? 's' : '';
    dayText = `${days} Day${plural}`;
  }

  return [
    `Ends <strong>${dayText}</strong> ${hours}:${normalizeDigit(minutes)}:${normalizeDigit(seconds)}`,
    t,
  ];
};
